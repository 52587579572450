// global vars
$htPrimaryGreen: #006978;
$htPrimaryLight: #00acc1;
$htPrimaryYellow: #ffc107;
$dark: #0e0e0e;
$main-white-color: #fff;
$main-dark-color: #006978;
$link-color: #00acc1;
$htSecondaryLight: #dde3e8;
$htThirdLight: #f4f7fa;

$lightGrey: #f8fafb;
// $darkGrey: #e3e2e2;
$dropdownTextColor: #555;
$separatorColor: #006978;
$redColor: #ff0000;
$darkGrey:#979ea9;

// Media queries
$breakpoints: (xs: 576px, sm: 768px, md: 960px, lg: 1200px);

//  RESPOND ABOVE
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-above(sm) {}
@mixin respond-above($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (min-width: $breakpoint-value) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-below(sm) {}
@mixin respond-below($breakpoint) {
  // If the breakpoint exists in the map.
  @if map-has-key($breakpoints, $breakpoint) {
    // Get the breakpoint value.
    $breakpoint-value: map-get($breakpoints, $breakpoint);

    // Write the media query.
    @media (max-width: ($breakpoint-value - 1)) {
      @content;
    }

    // If the breakpoint doesn't exist in the map.
  } @else {
    // Log a warning.
    @warn 'Invalid breakpoint: #{$breakpoint}.';
  }
}

//  RESPOND BETWEEN
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include respond-between(sm, md) {}
@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

.kayfik_logo {
  height: 12vh !important;
  @include respond-below(md) {
    height: 10vh !important;
  }
}

.headerLinks {
  padding-right: 5px !important;
}
.cartPadding {
  padding: 1.125rem 1.25rem !important;
}
.searchHeaderLink {
  padding: 1rem 2.25rem !important;
  @include respond-below(md) {
    padding: 0 !important;
  }
  margin: 0rem 15px !important;
}
.searchSM {
  padding-right: 0px !important;
  margin-right: -15px !important;
}
.searchInput {
  background-color: $main-white-color;
}

.HeaderForMobile {
  -webkit-margin-before: 0;
  -webkit-margin-after: 0;
  -webkit-margin-start: 0;
  -webkit-margin-end: 0;
  -webkit-padding-start: 0;
  @include respond-below(sm) {
    .mt-4 {
      margin-top: 10px !important;
    }
    .cartPadding {
      font-size: 0.8rem !important;
    }
    .pb-3 {
      padding-bottom: 0px !important;
    }
  }
}
.bannerButton {
  margin-left: 0.5rem !important;
  @include respond-below(md) {
    margin-left: 0.05rem !important;
  }
}
// .slick-dots li button::before{
// 	background-color:red !important;
// 	content:"\A"!important;
// }
// margins and padding
body {
  font-family: "Graphik Arabic Web" !important;
  // display: block !important;
  overflow-y: overlay !important;
  -webkit-overflow-scrolling: touch;
  // background-clip: content-box  !important;
  // // overflow: auto;
  // scrollbar-gutter: stable !important;
  text-align: -webkit-auto !important;
}

body :lang(ar) {
  .textButtonDiv{
    padding-left: 0 !important;
    padding-right:15px !important;
  }
  .buttonTextDiv{
    padding-left: 15px !important;
    padding-right: 0.1rem !important;
  }
  .previewClose {
    right: unset !important;
    left:25px !important;
    @include respond-below(sm) {
      left:10px !important;
    }
  }
  .react-tel-input input[type="tel"] {
    direction: ltr !important;
    text-align: -webkit-right !important;
    padding-right: 15px !important;
  }
  .kayfik_header {
    display: flex;
    @include respond-below(sm) {
      display: none !important;
    }
  }
  .plLanding {
    @include respond-above(md) {
      padding-right: 20px !important;
    }
  }
  .socialFeed {
    i {
      padding-right: 0px;
      padding-left: 10px;
    }
  }
  .float-right {
    float: left !important;
  }
  .shop-sectionProducts {
    .mr-2 {
      margin-left: 0.5rem !important;
    }
  }
  .GridContainerAr {
    margin-right: 35px !important;
  }
  .separatorAfter {
    &:after {
      width: 0px;
    }
    &:before {
      width: 1px;
      height: 20px;
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: #8a8a8a;
    }
  }
  .selectArrow {
    svg {
      left: 0 !important;
      right: unset !important;
    }
  }
  .searchIcon {
    position: absolute;
    right: unset;
    left: 0;
    margin-left: 20px;
    // top: 15%;
  }
  .footerMain {
    .innerGridCont {
      @include respond-below(sm) {
        margin-left: 0px !important;
        margin-right: 0px !important;
      }
    }
  }
  .accordian_title {
    padding-left: 0 !important;
  }
  .verifyPhoneEdit {
    //margin-top:10px !important;
    margin-left: 0px !important;
    margin-right: 10px !important;
  }
  .react-tel-input .selected-flag .arrow {
    position: absolute;
    left: -15px;
  }
  .react-tel-input {
    margin-right: 10px;
    .dial-code {
      margin-right: 10px;
    }
  }
  @include respond-below(sm) {
    .productBadge{
      font-weight:400 !important;
    }
  }
  .stop-scrolling{
    #main-logo{
      margin-right: 15px !important;
    }
    // @media not all and (min-resolution:.001dpcm) {  
    //     padding-left: 15px !important;
    //     padding-right: 0px !important;      
    // }
  }

  .orderStepperRoot{
    .StepProgress {
      padding-left: unset;
      padding-right: 45px;      
      &::before {
        left: unset;
        right: 22px;
      }      
      &-item {
        .stepContents{
          margin-right:18px;
          margin-left:unset;
          &::before,  &::after{
            right: -24px;
            top: 35px;
            border: solid transparent;
            content: " ";
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(194, 225, 245, 0);
            border-left-color: $main-white-color;
            border-width: 12px;
            margin-top: -12px;
            
          }
        }        
        &::before {
          right: -27px;
          left:unset;
        }        
        &::after {
          right: -35px;
          left:unset;
        }        
        &.is-done {
          &::after {
            right: -35px;
            left:unset;
          }
        }
      }
      .indicatorContainer{
        &::before {
          right: -33px;
          left:unset;
        }        
        &::after {
          right: -47px;
          left:unset;          
        }
      }  
    }
  }
.priceSection{
  .jcfe{
    justify-content: flex-start !important;
    direction: ltr;
    margin-left:10px;
  }
}

  .typeIcon{
    left:10%;
    right:unset;
    &.productDetailIcon{
      left:5%  !important;
      right: unset !important;
    }
  }
  .listing-images{
    @include respond-below(sm) {
      padding-right: 5px;
    }
  }
}

.footerMain {
  @include respond-below(md) {
    padding: 1rem 0 4.875rem 0 !important;
  }
}
.m-0 {
  margin: 0 !important;
}
.m-none {
  margin: "none" !important;
}
.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
  @include respond-below(sm) {
    margin-right: unset !important;
  }
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
  @include respond-below(sm) {
    margin-left: unset !important;
  }
}

.mt-5rem {
  margin-top: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}
.px-6 {
  padding-right: 3.5rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}
.px-6 {
  padding-left: 3.5rem !important;
}
.px-7 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}
.jcc {
  justify-content: center;
}
.jcfs {
  justify-content: flex-start !important;
}

.input-border {
  padding-top: 10px !important;
  margin-top: 14px !important;
  border-width: 2px !important;
}
.f-1 {
  flex: 1;
}

.f-d-r {
  flex-direction: row !important;
}
.bg-pl {
  background-color: $htPrimaryLight !important;
}
.color-pl {
  color: $htPrimaryLight !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fsize-20 {
  font-size: 20px !important;
}
.fsize-30 {
  font-size: 30px !important;
}
.fsize-17 {
  font-size: 17px !important;
}
.bg-w {
  background: white !important;
  padding: 40px 0px;
}
.bg-y {
  background: $htPrimaryYellow !important;
}
.-mt-20 {
  margin-top: -20px !important;
}
.-mb-12r {
  margin-top: 6.35rem !important;
}

.info-m {
  margin: 0rem 0 0.875rem !important;
}
.bg-red {
  background-color: red !important;
}

.buttonMargin {
  margin-top: 10 !important;
  margin-bottom: 20 !important;
}
// Border
.br-0 {
  border-radius: 0 !important;
}
.float-right {
  float: right !important;
}
// border color and text color htPrimaryLight for white buttons
.floating-yellow-icon-btn {
  position: absolute !important;
  left: 1rem;
  color: $htPrimaryYellow !important;
  top: inherit !important;
  margin-top: 0 !important;
  font-size: 1.5rem !important;
}
.floating-yellow-icon-btnAR {
  position: absolute !important;
  right: 1rem;
  color: $htPrimaryYellow !important;
  top: inherit !important;
  margin-top: 0 !important;
  font-size: 1.5rem !important;
}

.main-background-color {
  background-color: $main-white-color !important;
}

.footer-bg-color {
  background: $main-dark-color !important;
}

.white-text {
  color: $main-white-color !important;
  font-weight: 400 !important;
}

.img-fluid {
  width: 100% !important;
  height: 100px !important;
  object-fit: contain;
  // max-height: 150px;
}

.img-fluidMakeBid {
  width: 100% !important;
  //height: 100px !important;
  object-fit: contain;
  // max-height: 150px;
}
.icon-small {
  font-size: 20 !important;
  height: 2.5rem !important;
  width: 2.5rem !important;
  color: green;
}
.pl-20 {
  padding-left: 20px !important;
}
@include respond-below(sm) {
  .icon-small {
    height: 1.5rem !important;
    width: 1.5rem !important;
  }
}
.dp-f {
  display: flex !important;
}
.dp-c {
  display: contents;
}
.arrowToTop {
  position: fixed !important;
  right: 50px !important;
  bottom: 50px !important;
  z-index: 100 !important;
  color: $htPrimaryYellow !important;
  background: $htPrimaryGreen !important;
  @include respond-below(sm) {
    right: 25px !important;
    bottom: 65px !important;
  }
}

.arrowToTopAR {
  position: fixed !important;
  left: 50px !important;
  bottom: 50px !important;
  z-index: 100 !important;
  color: $htPrimaryYellow !important;
  background: $htPrimaryGreen !important;
  @include respond-below(sm) {
    left: 25px !important;
    bottom: 65px !important;
  }
}

.bottomBar {
  position: fixed !important;
  right: 0px !important;
  bottom: 0px !important;
  z-index: 100 !important;
  color: $htPrimaryGreen !important;
  background: $main-white-color !important;
}
.bottomBarShadow {
  // box-shadow: inset 0px 11px 8px -10px #CCC, inset 0px -11px 8px -10px #CCC !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.59), 0 6px 6px rgba(0, 0, 0, 0.23) !important;
}
.bottomBarIcon {
  width: 25px !important;
  height: 25px !important;
}
.badgePositionCart {
  // right: 9% !important;
  top: 17% !important;
}
.bottomBarItem {
  height: 50px !important;
}

.flex-sm-column {
  @include respond-below(sm) {
    display: flex !important;
    flex-direction: column !important;
  }
}
.boxShadowUnset {
  box-shadow: unset !important;
}
.d-flex {
  display: flex !important;
}

.bannerSMmargin {
  margin-top: 2.5rem !important;
}

.btn-width-100 {
  min-width: 100% !important;
}
.minWidth260 {
  // min-width: 260px !important;
  min-width: 450px !important;
}
.emptyCart{
  min-width: 450px !important;
  min-height:80px;
}
.btn-link {
  color: $link-color !important;
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}

.pointer-auto {
  cursor: auto !important;
}

.fancy-horizontal-line {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  position: relative;
}
.position-relative {
  position: relative;
}
.fancy-horizontal-line:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px $htPrimaryGreen;
  position: absolute;
  left: 0;
  top: 50%;
}

.fancy-content {
  background-color: $main-white-color; /* Same as the parents Background */
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px;
  color: $htPrimaryGreen;
  position: relative;
}

.big-icon {
  width: 5rem !important;
  height: 5rem !important;
}

.icon-primarylight {
  color: $htPrimaryLight;
}

.justify-center {
  justify-content: center;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.cartActionButtons {
  @include respond-below(sm) {
    font-size: 14px !important;
  }
}
.partnerProductListing {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 65vh;
  // padding-bottom: 40px;
  [id^=last]{
    margin-bottom:10px !important
  }
  [id^=first]{
    margin-top:10px !important
  }
}
.cartContainerScroll {
  min-height: 30vh;
  max-height: 70vh;
  overflow-y: auto;
}
.plSM0 {
  padding-left: 0px !important;
  @include respond-below(sm) {
    padding-left: 0px !important;
  }
}
.align-items-center {
  align-items: center !important;
}
.align-items-fe {
  align-items: flex-end;
}
.bg-secondary-light {
  background-color: $htThirdLight !important;
}

.ht-secondary-light {
  background-color: $htSecondaryLight !important;
}

.bg-grey {
  background: $lightGrey !important;
}
.bg-dark-grey {
  background: $darkGrey !important;
}
.kayfik-dark-grey {
  background: #FFFFFF !important;
}
.colorGrey {
  color: grey !important;
}
.qtyLabel {
  font-size: 10px !important;
}
.mx-2rem {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.pt2rem {
  padding-top: 2rem !important;
}
.marginAuto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.paddingAuto {
  padding-left: auto !important;
  padding-right: auto !important;
}
.colorYellow {
  color: $htPrimaryYellow !important;
}
.ta-left {
  text-align: left !important;
}
.ta-right {
  text-align: right !important;
}
.ta-initial {
  text-align: initial !important;
}
.ta-end {
  text-align: end !important;
  @include respond-below(md) {
    text-align: unset !important;
  }
}
.borderBottom {
  border: 0.4px solid #bababa !important;
}
.d-i-f {
  display: inline-flex !important;
}
.display-none {
  display: none !important;
}
.separator .landingContent {
  font-size: 22px !important;
}
.separatorAfter {
  &:after {
    width: 1px;
    height: 20px;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: $separatorColor;
  }
}
.cardHeight {
  height: 178px !important;
  // @include respond-below(md) {
  // 	height: auto !important;
  // }
}
.cardHeightImg {
  height: 178px !important;
  @include respond-below(md) {
    height: auto !important;
  }
}
.modalScroll {
  max-height: 600px;
  overflow-y: auto;
  margin-bottom: 20px !important;
}
.scrollbar::-webkit-scrollbar {
  width: 5px;
}
.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255,255, 1);
}
.scrollbar::-webkit-scrollbar-thumb {
  background-color: $htPrimaryLight;
  outline: 1px solid slategrey;
  border-radius: 5px;
}

.taWebAuto {
  text-align: -webkit-auto !important;
}
.dropzoneContainer {
  height: 58px !important;
  padding: 0 !important;
  border-style: unset !important;
  margin-bottom: 0 !important;
}
.justifyContentSpaceBetween {
  justify-content: "space between !important";
}
.similarProductsIcons {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center !important;
}
.jcfe {
  justify-content: flex-end !important;
}
.flex-wrap {
  flex-wrap: wrap !important;
}
.inputIconContainer {
  width: 100% !important;
  justify-content: space-between !important;
  align-items: center !important;
}
.htPrimaryGreen {
  color: $htPrimaryGreen !important;
}
.dropdownItemView {
  text-align: right;
}
.w-100 {
  width: 100% !important;
}
.h-100 {
  height: 100% !important;
}
.h-100x {
  height: 100px !important;
}
.favHeart {
  position: absolute !important;
  color: $htPrimaryYellow !important;
  font-size: 20px !important;
  right: 25px !important;
  top: 10px !important;
}
.profile {
  font-size: 18px !important;
  text-transform: uppercase;
  font-weight: 400;
}
.textTransformNone {
  text-transform: none !important;
}
.dashboard {
  display: flex;
  justify-content: center;
  align-items: center;
}
.previewSelect {
  width: 100px !important;
}
.footerImages {
  width: 60% !important;
  height: 40px !important;
}
.fd-rr {
  flex-direction: row-reverse !important;
}
.fd-c {
  flex-direction: column !important;
}
.display-none {
  display: none !important;
}
.display-block {
  display: block !important;
}
//accordian for company modal
.children {
  margin-left: 25px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.f15 {
  font-size: 15px !important;
}
.modalAvatar {
  z-index: 900 !important;
}
.previewCarouselHeight img {
  height: 445px !important;
  object-fit: contain;
}
.previewCarouselthumbnail img {
  height: 150px !important;
  object-fit: contain !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.previewCarouselthumbnail {
  width: 25% !important;
  object-fit: contain;
}
.carouselthumbnail {
  width: 258px !important;
  object-fit: contain;
}
.carouselthumbnail img {
  height: 150px !important;
  object-fit: contain !important; // previously it "contain"
  padding: 2px !important;
  @include respond-below(sm) {
    margin-bottom: 15px !important;
  }
}
.parentpreviewCarouselthumbnail {
  display: flex !important;
  // 
}
.taglineBody {
  padding: 3rem 1.875rem !important;
  @include respond-below(sm) {
    padding: 0.9375rem 15px !important;
    h2 {
      font-size: 1.125rem !important;
    }
  }
}
.landingCarouselSMGrid {
  @include respond-below(sm) {
    margin-top: -10% !important;
    h1 {
      font-size: 2rem !important;
    }
  }
}
.taglineCard {
  @include respond-below(sm) {
    padding-left: -15px !important;
    padding-right: -15px !important;
  }
}

.product-category-carousel {
  .slick-prev:before,
  .slick-next:before {
    color: $htPrimaryYellow;
    font-size: 40px;
    @include respond-below(md) {
      font-size: 30px !important;
    }
    @include respond-below(sm) {
      font-size: 20px !important;
    }
  }
  button.slick-arrow.slick-prev,
  button.slick-arrow.slick-next {
    opacity: 1;
    top: 36%;
    width: 4%;
    @include respond-below(sm) {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    margin-left: 55px;
    margin-right: 55px;
    @include respond-below(sm) {
      margin-left: 30px;
      margin-right: 30px;
    }
  }
}

.carouselHeight img {
  height: 500px !important;
  object-fit: contain;
  @include respond-below(sm) {
    height: 270px !important;
  }
}
.cartIcon {
  font-size: 26px !important;
}
.cartIconHeader svg {
  width: 30px !important;
  height: 30px !important;
  @include respond-below(md) {
    width: 25px !important;
    height: 25px !important;
  }
}
.favProductDetail {
  right: 10px !important;
  color: $main-white-color !important;
}
.cartDropdownContainer {
  // position:relative; //absolute
  top: 93px;
  right: 0;
  width: 500px; // safariChange
  background: $main-white-color;
}
.profileDropdownContainer {
  width: 450px !important;
}
.cartDropdownButton {
  width: 500px;
  background: $htSecondaryLight;
}
.cartScroll {
  max-height: 500px;
  overflow-y: auto;
}
.cartImgHeight {
  height: 100px;
  width: 100%;
}

.overflowXHidden {
  overflow-x: hidden !important;
}
.arrow_box {
  background: $htSecondaryLight;
  border: 4px solid $htSecondaryLight;
}
.arrow_box:after,
.arrow_box:before {
  bottom: 100%;
  left: 85%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.bg-transparent {
  background: rgba(255, 255, 255, 0) !important;
}
.bg-color-transparent {
  background-color: rgba(255, 255, 255, 0) !important;
}
.arrow_box:after {
  border-color: rgba(213, 2, 2, 0);
  border-bottom-color: $htSecondaryLight;
  border-width: 10px;
  margin-left: 10px;
}
.arrow_box:before {
  border-color: rgba(245, 47, 47, 0);
  border-bottom-color: $htSecondaryLight;
  border-width: 15px;
  margin-left: 10px;
}
.arrow_box_profile {
  background: $htSecondaryLight;
  border: 4px solid $htSecondaryLight;
}
.arrow_box_profile:after,
.arrow_box_profile:before {
  bottom: 100%;
  left: 61.5%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box_profile:after {
  border-color: rgba(213, 2, 2, 0);
  border-bottom-color: $htSecondaryLight;
  border-width: 10px;
  margin-left: 3px;
}
.arrow_box_profile:before {
  border-color: rgba(245, 47, 47, 0);
  border-bottom-color: $htSecondaryLight;
  border-width: 15px;
  margin-left: 3px;
}
.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

//Responsive header breakpoints
h1 {
  @include respond-below(sm) {
    margin-right: unset !important;
    font-size: 2.25rem !important;
  }
}
h2 {
  @include respond-below(sm) {
    font-size: 1.5625rem !important;
  }
}
h3 {
  @include respond-below(sm) {
    font-size: 1.125rem !important;
  }
}
h4 {
  @include respond-below(sm) {
    font-size: 1.0625rem !important;
  }
}
h5 {
  @include respond-below(sm) {
    font-size: 0.75rem !important;
  }
}
h6 {
  @include respond-below(sm) {
    font-size: 0.65rem !important;
  }
}

.pdr-15px {
  padding-right: 15px !important;
}
.visibility-hidden {
  visibility: hidden;
}
.sectionSpace {
  height: 70px !important;
  display: block !important;
  @include respond-below(sm) {
    height: 40px !important;
  }
}
.text-uppercase {
  text-transform: uppercase;
}
.color-dark {
  color: $dark !important;
}
.text-transform-none {
  text-transform: none !important;
}
.accordianIcon {
  margin-right: 85% !important;
}
.formLabelmb {
  margin-bottom: 7px !important;
}
.bannerIconSize i {
  font-size: 35px;
  @include respond-below(md) {
    font-size: 25px;
  }
}
.underline {
  text-decoration: underline !important;
}
.buttonHover :hover {
  color: $main-white-color !important;
}


.whatSellingButtons {
  padding: 12px 30px !important;
  font-size: 20px !important;
  @include respond-below(sm) {
    // padding: 5px 15px !important;
    // font-size: 16px!important;
  }
}
.jccSm {
  @include respond-below(sm) {
    justify-content: center !important;
  }
}
.landingContainer {
  padding-left: 20px !important;
  padding-right: 20px !important;
  margin-right: auto !important;
  margin-left: auto !important;
  width: 100% !important;
  @include respond-below(md) {
    padding-left: 15px !important;
    padding-right: 15px !important;
    z-index: 2000 !important;
  }
}
.plLanding {
  @include respond-above(md) {
    padding-left: 52px !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none !important;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield !important; /* Firefox */
}
.hideMD {
  display: none !important;
  @include respond-below(md) {
    display: block !important;
  }
}

.hideMobile {
  @include respond-below(md) {
    display: none !important;
  }
}
.kayfikSM {
  -webkit-box-shadow: -1px 3px 5px 1px rgba(163, 163, 163, 0.5) !important;
  -moz-box-shadow: -1px 3px 5px 1px rgba(163, 163, 163, 0.5) !important;
  box-shadow: -1px 3px 5px 1px rgba(163, 163, 163, 0.5) !important;
}
.previewClose {
  position: absolute !important;
  z-index: 100 !important;
  right: 25px !important;
  @include respond-below(sm) {
    right:35px !important;
  }
}
.previewCloseLTR {
  position: absolute !important;
  z-index: 100 !important;
  right: 25px !important;
  @include respond-below(sm) {
    right:35px !important;
  }
}
.box {
  display: block;
  text-align: right;
  position: relative;
  direction: "rtl";
}

.slice-mid {
  direction: "rtl";
  position: absolute;
  margin: 0;
  top: 0.5rem;
  width: 0px;
  right: 0px;
  z-index: 2;
  visibility: hidden;
  @include respond-below(md) {
    top: -0.9rem;
    right: 20px;
  }
}

.box:hover .slice-mid {
  direction: "rtl";
  visibility: visible;
  width: 40vw;
  margin-left: 200px;
  transition: 0.5s ease-in-out;
}
.roomIcon {
  width: 18px !important;
  margin-left: 5px;
}
.mapContainer {
  position: relative;
  height: 70vh;
  @include respond-below(sm) {
    height: 55vh !important;
  }
  .currentLocation{
    position: absolute;
    bottom:5%;
    left:2%;
    cursor: pointer;
  }
}
.confirmContainer{
  height: 18vh;
  @include respond-below(sm) {
    height: 15vh !important;
  }
}
.companyStepper {
  padding-left: 9vw !important;
  padding-right: 20vw !important;
}
.stepperButtonsContainer {
  display: flex !important;
  flex-direction: row !important;
  padding-bottom: 15px !important;
  @include respond-below(sm) {
    .jcfs {
      justify-content: center !important;
    }
    .jcfe {
      justify-content: center !important;
      padding-bottom: 10px !important;
    }
    .fw-500 {
      display: flex !important;
      justify-content: center !important;
    }
  }
}
.backButtonPadding {
  padding-left: 7px !important;
  @include respond-below(sm) {
    padding-left: 15px !important;
  }
}
.continuePadding {
  padding-right: 0px !important;
  @include respond-below(sm) {
    padding-right: 15px !important;
  }
}
.maxHeight80 {
  max-height: 80px !important;
}
.maxHeight150 {
  max-height: 150px !important;
}
.mr-30- {
  margin-right: -55px !important;
}
.product-detail-page {
  @include respond-between(sm, md) {
    .px-5 {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
    .pt-5 {
      padding-top: 1rem !important;
    }
    h6 {
      margin-top: 5px !important;
      margin-bottom: 5px !important;
    }
    .pd-text {
      font-size: 0.8rem !important;
    }
  }
}
.sectionGridArrow {
  height: 70px !important;
  width: 70px !important;
}
.image-gallery-bullets .image-gallery-bullet {
  background-color: $main-white-color !important;
  border: 0;
}
.image-gallery-bullets .image-gallery-bullet.active {
  background: $htPrimaryLight !important;
  border: 0;
}
.slick-dots li button::before {
  border-radius: 50%;
  width: 10px;
  height: 10px;
  content: "\A";
  opacity: 1;
  background-color: #3f3f3f;
  /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2); */
}
.slick-dots li.slick-active button:before {
  width: 10px;
  height: 10px;
  top: 0;
  background-color: $htPrimaryLight;
}

.WithTheme-WithWidth-Connect-HeaderLinks----listItem-182:not(:last-child):after {
  height: 0px !important;
}
.socialFeed {
  p {
    display: table-cell;
    vertical-align: top;
    overflow: hidden;
    padding-bottom: 10px;
    max-width: 300;
  }
  i {
    font-size: 20px;
    display: table-cell;
    padding-right: 10px;
  }
}

.shopProductImage {
  max-height: 280px;
}
.appResponsiveMobile {
  height: 85% !important;
}
.colorTransparent {
  color: rgba(255, 255, 255, 0);
}
.search {
  display: flex;
  flex-direction: row;
  // align-items: center;
}

.search input.search-text {
  // height: 50px;
  border: 0 !important;
  color: #555;
  margin: 0 auto;
  width: 200px;

  transition: width 700ms, background 700ms;

  -webkit-transition: width 700ms, background 700ms;
  -moz-transition: width 700ms, background 700ms;
  -ms-transition: width 700ms, background 700ms;
  -o-transition: width 700ms, background 700ms;

  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  @include respond-below(md) {
    padding: 8px !important;
    width: 80px !important;
  }
}
.search input.search-text:focus {
  background-color: #e1f0f3;
  width: 450px;
  @include respond-below(md) {
    width: 150px !important;
  }
  @include respond-below(xs) {
    width: 120px !important;
  }
}

// .search-icon:hover{
// 	background-color: #e1f0f3;
// 	width:450px;
// }
.searchIcon {
  position: absolute;
  right: 0;
  top: 20%;
  width: 30px !important;
  height: 30px !important;
  @include respond-below(md) {
    width: 25px !important;
    height: 25px !important;
    top: 25%;
  }
}
.sort_by {
  select {
    font-size: 0.7rem !important;
  }
}
.prodGridImage {
  display: flex;
  height: 30vh;
  justify-content: center;
  align-items: center;
}
.minHeight30 {
  min-height: 30vh !important;
}

.headerColor {
  //background: #B3B3B3;
  color: black;
}
.headerLink {
  cursor: pointer;
}

.product_Image {
  object-fit: contain;
  height: 100%;
}

.no-hover :hover {
  color: transparent !important;
  background-color: transparent !important;
  box-shadow: none !important;
}

.dropdown-color {
  color: $dropdownTextColor !important;
}

.bg-color-primaryyellow {
  background-color: $htPrimaryYellow !important;
}

.full-height {
  min-height: 73vh !important;
  // height: 100% !important;
  @include respond-below(md) {
    // height: 100% !important;
    min-height: unset !important;
  }
}

.height-30 {
  height: 30%;
}

.height-70 {
  height: 70%;
}
.centered {
  align-self: center;
  justify-self: center;
  width: 30px;
  position: absolute;
  z-index: 999;
  height: 40px;
  object-fit: contain;
  left: 50%;
}

.custom-search-icon {
  padding: 0.68rem 0 !important;
  min-width: 0px !important;
  // background: #00ACC1 !important;
  margin-right: 25px !important;
  margin-left: 25px !important;
}

.custom-nav-icon {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
  // color:#006978 !important;
}
.z-index-1 {
  z-index: 1099 !important;
  @include respond-below(sm) {
    z-index: 3333 !important;
  }
}

.d-none {
  &.sm {
    @include respond-below(md) {
      display: none !important;
    }
  }
  &.md {
    @include respond-above(sm) {
      display: none !important;
    }
  }
}

.checkout-header {
  padding-top: 3rem;
  padding-bottom: 3rem;
  @include respond-below(sm) {
    padding-top: 0.9375rem;
    padding-bottom: 0.9375rem;
    h2 {
      font-size: 1.125rem !important;
    }
  }
}

.min-70vh {
  min-height: 70vh;
}

.min-100vh {
  min-height: 100vh;
}

.profile-Align {
  text-align: right;
}
.rotate-45 {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-top: 10px;
  @include respond-below(sm) {
    // margin-top:0px;
  }
}

.rotate-50 {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  margin-top: 10px;
  @include respond-below(sm) {
    margin-top: 0px;
  }
}
.inputAR {
  font-size: 1rem;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 8px;
  padding-bottom: 8px;
}
.postion {
  position: inherit;
}
.expansionPanelSummaryExpandIcon {
  position: inherit;
  margin-top: 0px;
}

.product-carousel {
  .productCarsole {
    flex-direction: row-reverse;
    text-align: right;
  }
}

.product-list {
  .productCarsole {
    text-align: right;
    @include respond-below(md) {
      flex-direction: row-reverse !important;
    }
  }
}

.zoom:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}
form .required {
  &:after {
    color: red;
    content: " *";
  }
}
.header-title {
  color: $main-white-color !important;
  font-weight: 400 !important;
  font-size: 2.8rem !important;
  @include respond-below(sm) {
    font-size: 1.5rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    padding-bottom: 0rem !important;
  }
}
.sub-title {
  color: $main-white-color !important;
  font-weight: 400 !important;
  font-size: 2rem !important;
  @include respond-below(sm) {
    font-size: 1rem !important;
    padding-left: 0.5rem !important;
    // padding-right: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

.header-container {
  padding: 30px;
  @include respond-below(sm) {
    padding: 0;
  }
}
.color-title {
  color: $htPrimaryLight !important;
  font-weight: 400 !important;
  font-size: 2rem !important;
  @include respond-below(sm) {
    font-size: 1rem !important;
  }
}
.dived {
  line-height: 1.8;
}
.lineHt1 {
  line-height: 24px !important;
}
.grey-background {
  background: $lightGrey !important;
}
.about-title {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  @include respond-below(sm) {
    padding-right: 0rem !important;
    padding-left: 1rem !important;
  }
}
.about-header {
  padding: 1rem !important;
  line-height: 1.5em;
  @include respond-below(sm) {
    padding: 0rem !important;
    padding-left: 1rem !important;
    padding-bottom:0.5rem !important;
  }
}
.about-body {
  margin-left: 100px !important;
  @include respond-below(sm) {
    margin-left: 20px !important;
    padding-right: 0px !important;
    padding-left: 25px !important;
    padding-top: 1rem !important;
  }
}
.about-bodyAR {
  margin-right: 100px !important;
  @include respond-below(sm) {
    margin-right: 20px !important;
    padding-right: 0px !important;
    padding-right: 25px !important;
    padding-top: 1rem !important;
    padding-left: 0px !important;
  }
}
.marginFit {
  margin-left: auto !important;
  // margin-right: auto !important;
}
.marginRight {
  // margin-left: auto !important;
  margin-right: auto !important;
}
.section-title {
  margin-top: 3rem !important;
  @include respond-below(sm) {
    padding-left: 1.5rem;
  }
}
.section-titleAR {
  margin-top: 3rem !important;
  // padding-right: 9rem!important;
  @include respond-below(sm) {
    padding-right: 1.5rem !important;
  }
}
.section-des {
  font-size: 17px !important;
  padding-left: 3rem !important;
  // padding-right: 3rem !important;
  @include respond-below(sm) {
    font-size: 14px !important;
    padding-left: 3rem !important;
  }
}

.section-desAR {
  font-size: 17px !important;
  // padding-left: 3rem !important ;
  padding-right: 4rem !important;
  @include respond-below(sm) {
    font-size: 14px !important;
    padding-right: 3.5rem !important;
  }
}
.accordian-title {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  @include respond-below(sm) {
    padding-left: 3rem !important;
    padding-right: 0px !important;
  }
}

.accordian-titleAR {
  padding-left: 0rem !important;
  padding-right: 3rem !important;
  // direction: initial;
  @include respond-below(sm) {
    padding-left: 0rem !important;
    padding-right: 3rem !important;
  }
}

.accordian-two {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  @include respond-below(sm) {
    padding-left: 3.5rem !important;
    padding-right: 0px !important;
  }
}
.dropDown {
  padding-right: 0px !important;
}

.customlineheight {
  line-height: 1.1875em !important;
}

.toggleFix {
  display: flex !important;
  justify-content: space-between;
  width: 60% !important;
}
.jcsb {
  justify-content: space-between;
}
.alignItem {
  align-items: center !important;
}
.product-title {
  cursor: pointer;
  max-width: 55%;
}
.share {
  padding-bottom: 0.5rem !important;
}
.quick {
  padding-top: 0.5rem !important;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  @include respond-below(sm) {
    padding-right: 1rem !important;
    padding-left: 0rem !important;
  }
}
.minimum {
  min-width: 0px;
  padding: 5px;
}
.minWidth {
  min-width: 0px !important;
}
.banner {
  padding: 10px;
  @include respond-below(sm) {
    padding: 5px;
  }
}

.worksMobile {
  @include respond-below(sm) {
    padding: 5px !important;
  }
}

.bottomBarText {
  width: 100% !important;
  padding-left: 37px !important;
  background: $main-white-color !important;
  color: $htPrimaryGreen !important;
}

.bottomBarTextAR {
  width: 100% !important;
  padding-right: 42px !important;
  background: $main-white-color !important;
  color: $htPrimaryGreen !important;
}

.bottomBarItemButtom {
  width: 100% !important;
  // padding: 0.80rem 4.25rem !important;
  background: $main-white-color !important;
  color: $htPrimaryGreen !important;
  padding: 0px !important;
}

.bottomBarItemButtom:hover {
  color: none !important;
  box-shadow: none !important;
}

.mobileButtons {
  padding-left: 5px !important;
  padding-right: 5px !important;
  // border:1px solid red !important;
}
.searchBariOS {
  -webkit-appearance: none !important;
}
.popoverPosition {
  bottom: 8% !important;
  left: 15% !important;
}
.vw100 {
  width: 100vw !important;
}
.maxCartPopover{
  max-height:50vh !important;
  overflow-y: auto !important;
  min-height:80px !important;
  bottom:60px;
  top: unset !important;
  &.cart_empty{
    display: flex;
    align-items: center;
    justify-content: center;    
  }
}
.scrollToTopTouch {
  touch-action: none !important;
}
.userName-capitalize {
  text-transform: capitalize !important;
}

.quickAdd {
  padding-bottom: 15px !important;
}
.product {
  margin-left: 15px;
  margin-right: 0px;
}

.productAR {
  margin-left: 0px;
  margin-right: 20px;
}

.stop-scrolling {
  overflow: hidden !important;
  // .dummyScroll{
  //   display: block;
  // }
}
@media not all and (min-resolution:.001dpcm) {
  .stop-scrolling { 
    padding-right: 15px;
  }
  // .stop-scrolling :lang(ar) { 
  //     padding-left: 15px !important;
  //     padding-right: 0px !important; 
  // }
  body:lang(ar){
    &.stop-scrolling{
      padding-left: 15px !important;
      padding-right: 0px !important; 
    }
  }
}
.stopBody-scrolling {
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch;
}
.modalCs {
  -webkit-overflow-scrolling: touch !important;
  overflow-y: overlay;
}
.overlayy {
  overflow-y: overlay !important;
}
.longList {
  overflow: visible !important;
}

.react-tel-input {
  position: absolute !important;
  z-index: 5 !important;
  margin-top: 5px !important;
  margin-right: 25px;
}
.react-tel-input input[type="tel"] {
  background: rgba(255, 255, 255, 0) !important;
  border: 0px solid #cacaca !important;
  width: 90% !important;
  margin-right: 25px;
}
.react-tel-input .flag-dropdown {
  background: rgba(255, 255, 255, 0) !important;
  border: 0px solid #cacaca !important;
}
// .react-tel-input .flag-dropdown :hover{
// 	background: rgba(255, 255, 255, 0) !important;
// 	border: 0px solid #CACACA !important;
// }
.react-tel-input .flag-dropdown:hover .selected-flag {
  background: rgba(255, 255, 255, 0) !important;
}
.react-tel-input .country-list .search-emoji {
  display: none !important;
}
.react-tel-input .country-list .search-box {
  border: 0px solid #cacaca !important;
  margin-left: 0px !important;
  width: 100%;
}
.react-tel-input .flag-dropdown.open-dropdown .selected-flag {
  background: rgba(255, 255, 255, 0) !important;
}
.disabled {
  pointer-events: none;
}

.category {
  margin-bottom: 20px;
  @include respond-below(sm) {
    margin-bottom: 20px !important;
  }
}

.account_header {
  @include respond-below(sm) {
    height: 50px !important;
    padding: 10px !important;
  }
}
.previewProduct {
  object-fit: contain;
  height: 550px;
  width: 100%;
  @media only screen and (max-width: 450px) {
    height: 300px;
    //margin-top: 40px;
  }
  @media only screen and (width: 768px) {
    height: 500px;
    //	margin-top: 40px;
  }
}

.completeButton {
  padding: 0px;
  justify-content: flex-end;
  padding-right: 0px !important;
}

.strightline {
  width: 100%;
  background-color: #00acc1;
}

.completeButton {
  padding: 0px;
  justify-content: flex-end;
  padding-right: 0px !important;
}

.strightline {
  width: 100%;
  background-color: #00acc1;
}

.Select-menu-outer {
  z-index: 1000 !important;
}

.Select-option.is-selected {
  display: none !important;
}
.relativePos {
  position: relative;
}

.react-tel-input input[type="tel"] {
  font-family: "Graphik Arabic Web" !important;
  color: #3c4858 !important;
  font-size: 1rem !important;
}
.redColor {
  color: red !important;
}
.svg_icons {
  transform: scale(1);
  margin-top: 3px;
  margin-bottom: 1px;
  @include respond-below(sm) {
    transform: scale(0.8);
    margin-top: 1.5px;
  }
}

.company_icons {
  transform: scale(1.2);
  margin-bottom: 3px;
  @include respond-below(sm) {
    transform: scale(1.3);
    margin-top: 5px;
    font-size: 15px  !important;
  }
}

.subscribe {
  padding: 1.18rem 2.25rem !important;
}

.sell_button {
  margin-left: 100px !important;
}

.green {
  color: #006978;
}
.subscribe {
  padding: 1.18rem 2.25rem !important;
}
.sell_button {
  margin-left: 100px !important;
}

.sell_buttonAR {
  margin-right: 100px !important;
}
.buy_buttonAR {
  margin-right: 25px !important;
  @include respond-below(sm) {
    margin-right: 15px !important;
  }
}

.accordian_title {
  padding-left: 65px !important;
  font-size: 2rem !important;
  @include respond-below(md) {
    padding-left: 15px !important;
    font-size: 1.5rem !important;
  }
}
.deleteButtonDiv {
  position: absolute;
  bottom: 5px;
  right: 5px;
}
.menu {
  border: 1px solid red !important;
}

.image_back {
  transform: scale(1.3);
  margin-bottom: 2px;
  @include respond-below(sm) {
    margin-bottom: 1px;
  }
}
.text_wrap {
  word-wrap: break-word !important; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word !important; /* Renamed property in CSS3 draft spec */
  white-space: normal !important;
}
.contact_us {
  cursor: pointer;
  color: $htPrimaryLight !important;
  margin-right: 5px;
  @include respond-below(sm) {
    display: flex;
  }
}

.drop-zone-remove-icon {
  position: absolute !important;
  top: 0;
  right: 3%;
}

.rtlX {
  margin-left: -24px !important;
  margin-right: 24px !important;
}
#snackBarIconID {
  svg {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.rotate-factes {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  margin-right: 10px;
  @include respond-below(sm) {
    // margin-top:0px;
  }
}

.product_description {
  padding-top: 3rem !important;
  padding-bottom: 1.5rem !important;
  @include respond-below(sm) {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
}

// .category_title{
// 	margin-top: 10px !important;
// 	@include respond-below(sm) {
// 		margin-top: 10px !important;
// 	}
// }

.shopDataRow {
  height: 100px !important;
  display: flex !important;
  align-items: center !important;
}

.filter_mobile {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.profile_section {
  @include respond-below(sm) {
    margin-top: 15px !important;
    margin-bottom: 0px !important;
  }
}
.footer_bottombar {
  @include respond-below(sm) {
    padding-bottom: 56px !important;
  }
}

.footer_mobile {
  @include respond-below(sm) {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
}

.header-height {
  height: 100px;
  @include respond-below(md) {
    height: 75px;
  }
}

.bottom_cart {
  height: 100px !important;
  width: 170px !important;
  object-fit: contain !important;
  @media (max-width: 400px) {
    width: 100px !important;
  }
}

.category_page {
  padding-right: 1.2rem !important;
}

.bottom_items {
  margin-right: 1.5rem !important;
  cursor: pointer !important;
}

.bottom_itemsAR {
  margin-left: 1.5rem !important;
  cursor: pointer !important;
}

.Cart_height {
  height: 96px !important;
  width: 130px !important;
}

.buy_sell_divider {
  &:after {
    top: 0;
    width: 1px;
    right: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background-color: #e5e5e5;
  }
}

.buy_sell_divider_ar {
  &:after {
    top: 0;
    width: 1px;
    left: 0;
    bottom: 0;
    content: "";
    position: absolute;
    background-color: #e5e5e5;
  }
}

.floating-bg {
  @include respond-below(md) {
    display: none !important;
  }

  position: absolute;
  top: 0;
  bottom: -100vh;
  right: -100vw;
  left: 0;
}

.floating-bg-ar {
  @include respond-below(md) {
    display: none !important;
  }

  position: absolute;
  top: 0;
  bottom: -100vh;
  right: 0;
  left: -100vw;
}

.position-relative {
  position: relative;
}

.sm-margin-top {
  @include respond-below(sm) {
    margin-top: 10px !important;
  }
}

.bg-grey-md {
  @include respond-above(sm) {
    background: $lightGrey !important;
  }
}

.p-md-0 {
  @include respond-above(sm) {
    padding: 0 !important;
  }
}

#header {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.show-mobile {
  display: none;
  @include respond-below(md) {
    display: block;
    // margin-left: 3px;
    font-size:9px;
  }
}

.hide-mobile {
  display: block;
  // margin-left: 3px;
  @include respond-below(md) {
    display: none;
  }
}

.show-mobileAR {
  display: none;
  @include respond-below(md) {
    display: block;
    // margin-right: 8px;
    font-size: 9px;
  }
}

.hide-mobileAR {
  display: block;
  // margin-right: 5px;
  @include respond-below(md) {
    display: none;
  }
}

.show-camera {
  display: none;
  @include respond-below(sm) {
    display: block;
    // margin-left: 2px;
    font-size: 0.875rem !important;
  }
}

.hide-camera {
  display: block;
  @include respond-below(sm) {
    display: none;
  }
}

.show-cameraAR {
  display: none;
  @include respond-below(sm) {
    display: block;
    margin-left: 3px;
    font-size: 0.875rem !important;
    margin-bottom: 5px;
  }
}

.hide-cameraAR {
  display: block;
  @include respond-below(sm) {
    display: none;
    margin-left: 8px;
  }
}
.bottomBarLabel {
  font-size: 0.875rem !important;
  font-family: "Graphik Arabic Web" !important;
}

.text_align {
  @include respond-below(sm) {
    padding-right: 0px !important;
  }
}

.text_alignAR {
  @include respond-below(sm) {
    padding-right: 6px !important;
  }
}

.d-none-sm {
  @include respond-below(md) {
    display: none;
  }
}

.customPriceInputs {
  .low {
    padding-right: 0 !important;
  }
  .high {
    padding-left: 0 !important;
  }
  @include respond-below(md) {
    .low {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
    .high {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
}
.mt24 {
  margin-top: 20px !important;
}
.searchIconBase {
  padding-left: 0 !important;
  margin-top: -0.8vh !important;
  margin-left: 2px !important;
}
.hoverYellow {
  &:hover {
    color: $htPrimaryYellow !important;
  }
}
.verifyPhoneEdit {
  margin-top: 10px !important;
  margin-left: 10px !important;
}

.toggleFixAR {
  display: flex !important;
  justify-content: space-between;
  width: 60% !important;
  @include respond-below(sm) {
    width: 115% !important;
  }
}

.d-none-smd {
  @include respond-above(md) {
    display: none !important;
  }
}
.image-badge {
  position: absolute;
  right: 0;
  left: 0;
  top: 48%;
  background: rgba(142, 142, 142, 0) !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 0 !important;
}

.pendingVerificationBackground {
  background: #8e8e8e !important;
  padding: 5px 10px !important;
}

.image-badge-cart {
  right: unset !important;
  left: 9%;
}
.image-badge-prodDetail {
  top: 37% !important;
}

.productBadge-cart {
  font-size: 8px !important;
  word-wrap: break-word;
}
.productBadge-checkout {
  font-size: 8px !important;
  width: 100%;
  overflow: hidden !important;
}
.productBadge {
  font-size: 13px !important;
  font-weight: 600 !important;
  border-radius: 0 !important;
}
.d-none-sms {
  @include respond-below(md) {
    display: none !important;
  }
}
.arabic-text{
  margin-bottom:10px !important;
}

.display-sm-down {
  @include respond-above(sm) {
    display: none !important;
  }
}
.display-sm-up {
  @include respond-below(sm) {
    display: none !important;
  }
}
.icon-parent {
  // border:1px solid red;
  max-width: 100px !important;
}
.dropdownUserIcon {
  font-size: 60px !important;
  @include respond-below(md) {
    font-size: 40px !important;
  }
}
.banner_right{
  margin-left: 50px !important;
  font-weight: 600 !important;
    @include respond-below(md) {
      margin-left: 0px !important;
      padding: 6px !important;
    }
 }
.banner_left{
  margin-right: 40px !important;
  font-weight: 600 !important;
  @include respond-below(md) {
    margin-right: 0px !important;
    padding: 6px !important;
    font-weight: 400 !important;
  }
}
.d-none-smd-md {
  @include respond-above(md) {
    display: none;
  }
}

.sub-titleAR {
  color: $main-white-color !important;
  font-weight: 400 !important;
  font-size: 2rem !important;
  @include respond-below(sm) {
    font-size: 1rem !important;
    // padding-left: 1rem !important;
    padding-right: 1.5rem !important;
    padding-bottom: 1rem !important;
  }
}

.cms_subTitle{
  padding-right: 1rem !important;
  padding-left: 1rem !important;
  padding-bottom: 1rem !important;
  @include respond-below(sm) {
    padding-right:0px !important;
    padding-bottom:0px !important;
  }

}
.p5{
  padding: 0 3px !important;
}
.footer_logo{
  height: auto !important;
  width : 150px !important;
  @include respond-below(lg) {
   height: auto !important;
   width:100px !important;
  }
}
.footer_socialEN{
   text-align: left
}
.footer_socialAR{
  text-align:right
}
.arabic{
  direction:rtl;
}
.similar_arabic{
  direction:ltr;
  text-align: end;
}
.cart_arabic{
  direction:ltr;
  text-align: right;
}
.helpIcon{
  margin-top:8px !important;
}
.F16{
  font-size:16px!important;
}
.F12{
  font-size:12.8px!important;
}

.footer_linkAR {
  font-size: 14px;
  margin-right: 6px;
  cursor: pointer;
}

.footer_linkEN {
  font-size: 14px;
  margin-left: 6px;
  cursor: pointer;
}

.footer_paymentImg{
  height:80px !important;
  width : auto !important;
  @include respond-below(lg) {
   height: 50px !important;
   width:auto !important;
  }
}

.footer-button-image {
  width: 60% !important;
  height: 20px !important;
  @include respond-below(lg) {
    width: 60% !important;
    height:15px !important;
  }
}
.facets_mobile{
  opacity: 0.5 !important;
}
.p8{
  padding-left:8px !important;
  padding-right:8px !important;
}

.whatsellingContainer{
  display: flex !important;
  flex-direction: column;
  flex:0.5;
  // border:1px solid red;  
  justify-content: space-between;
  @include respond-below(sm) {
    padding-bottom:5vh;
  }
}
.cardBodyMinHeight{
  padding-bottom: 90px !important;
}
.cardOverflowVisible{
  overflow: visible !important;
}
.about-text {
  font-size: 1.5625rem;
  line-height: 1.4em;
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: "Graphik Arabic Web" !important;
  font-weight: 300;
  color: inherit;
  @include respond-below(sm) {
    font-size: 1rem;
  }
}
.buttonTextDiv{
  padding-left: 0.1rem !important;
}
.textButtonDiv{
  padding-right: 0 !important;
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {

  .continueContainer{
    @include respond-below(sm) {
      margin-bottom:150px !important;
      will-change: initial !important; // for safari bleeding 
    }  
  }
  }
 @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .safari_only { 
      height: 11vh;
    }
  }
}

.datepicker{
    position: absolute !important;
    // border: 2px solid red !important;
   
    margin-top: 25px !important;
    height: 60% !important;
    width: 90% !important;
    &.start{
      z-index: 5 !important;
    }
    &.end{
      z-index: 4 !important;
    }
}
.rdt input.form-control:focus {
  background-image: unset !important;
  background-size: 0 0, 100% 0 !important;
}

.greenLabelStyle{
  color: #006978;
  font-size: 0.8rem;
  text-align: -webkit-auto;
  font-family: "Graphik Arabic Web";
  font-weight: 400;
  line-height: 1.5em;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.req_action{
  background:$main-dark-color !important;
  padding: 5px 10px !important;
}
.font11{
  font-size:11px !important;
}
.country-list{
  display: none !important;
}
.flag{
  .arrow{
    display: none !important;
  }
}
.react-tel-input .flag-dropdown.open .selected-flag {
background: #DDE3E8 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag:hover {
background: #DDE3E8 !important;
}
.reqProdDateInput{
  input{
    height: 58px !important;
    padding: 18px 16px !important;
    font-size: 1rem!important;
    font-family: "Graphik Arabic Web" !important;
  }

  // .rdt .rdtPicker {
  //   display: none;
  // }
}
.rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover, .rdtMonths .rdtMonth.rdtActive, .rdtMonths .rdtYear.rdtActive, .rdtYears .rdtMonth.rdtActive, .rdtYears .rdtYear.rdtActive{
  background-color: $htPrimaryLight !important;
}
.priceInput{
  input::placeholder {
    color: #808080;
    opacity: 1;
  }    // color:#808080;
}
input::placeholder {
  color: #808080;
  opacity: 1;
  font-size: 1rem;
  font-family: "Graphik Arabic Web" !important;
}
.cardHeightDetail{
  height:80% !important;
}
.prodDetailQty{
  input{
    width:30px;
    height:30px;
    font-size:1rem;
    text-align: center;
    border:none !important;
  }
}
.count_right{
  margin-right: 10% !important;
}

.count_left{
  margin-left: 10% !important;
}

.count_badge{
  background-color: $main-dark-color !important;
  color: white !important;
}
.listed_item{
  // overflow: hidden !important;
  border-style: solid !important;
  border-width: 2px !important
}

.unavailable{
  @media only screen and (width: 320px) {
    font-size: inherit !important;
  }
}
.order_badge{
  padding-top: 18px !important;
  margin-right: 0px !important;
  float: right !important;
}
.order_badgeAR{
  padding-top: 18px !important;
  margin-right: 0px !important;
  float: left !important;
} 
.listing_badge{
  width: fit-content !important;
  float: right !important;
  padding: 0px !important;
  @include respond-below(md) {
    float: none !important;
    margin-top: 15px !important;
  }
}
.listing_badgeAR{
  width: fit-content !important;
  float: left !important;
  padding: 0px !important;
  @include respond-below(md) {
    float: none !important;
    margin-top: 15px !important;
  }
}
.font_order{
  font-size:11px !important;
  @include respond-below(sm) {
   font-size:8px !important;
   line-height: 1.5 !important;
 }
}
.order_status{
  // background:$dropdownTextColor !important;
  padding: 5px 10px !important;
  color: white !important;
  float: right !important
}

.dummyScroll{
  display: none;
  position: fixed;
  width:15px;
  top:0;
  right:0;
  height:100%;
  z-index: 4849;
  background-color:#ebe9e9;
}
.product-button-text{
  word-wrap: break-word !important; /* All browsers since IE 5.5+ */
  overflow-wrap: break-word !important; /* Renamed property in CSS3 draft spec */
  white-space: normal !important;
  @include respond-below(sm) {
    font-size: 12px !important; // for smaller devices
  }
}

.searchSignleLine {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @include respond-below(xs) {
    -webkit-line-clamp: 2;
  }
}

.push-snackbar {
  background-color: #FFFFFF !important;
  color: $htPrimaryGreen !important;
}

.push-button {
  color: $htPrimaryLight !important;
}

.cartItemTitle{
  white-space: normal;
}
.bred{
  border: 2px solid green !important;
}
.confirmEmailCardContainer{
  width:100vw;
  height:100vh;
  // border:1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  .confirmEmailCard{
    width:620px;
    border:1px solid $htPrimaryLight;
    padding:3rem 3rem;
    color: $htPrimaryGreen !important;
    h3{
      font-size:25px !important;
    }
    h5{
      font-size:17px !important;
    }
    // box-shadow: 0 2px 2px 0 rgba(0, 172, 193, 0.14), 0 3px 1px -2px rgba(0, 172, 193, 0.2), 0 1px 5px 0 rgba(0, 172, 193, 0.12);
    @include respond-below(sm){
      width:550px;
    }
    @include respond-below(xs){
      width:300px;
      padding:2rem 2rem;
      h3{
        font-size:20px !important;
      }
      h5{
        font-size:17px !important;
      }
    }
  }
}

.apply-coupan{
  padding: 1rem 2.25rem !important;
}

.orderStepperRoot{
  $small-circle:18px;
  .wrapper {
    width: 100%;
    padding:25px;
    // font-family: 'Helvetica';
    font-size: 14px;
    border: 1px solid #ececec;
    background-color: #ececec ;
  }
  
  .StepProgress {
    position: relative;
    padding-left: 45px;
    list-style: none;
    
    &::before {
      display: inline-block;
      content: '';
      position: absolute;
      top: 0;
      left: 22px;
      width: 10px;
      // height: 100%;
      // border-left: 2px solid #CCC;
    }
    
    &-item {
      position: relative;
      counter-increment: list;
      .stepContents{
        margin-left:18px;
        padding:15px;
        // bordeR:2px solid red;
        background: $main-white-color;
        position: relative;
        &::before,  &::after{
          right: 100%;
          top: 35px;
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(194, 225, 245, 0);
          border-right-color: $main-white-color;
          border-width: 12px;
          margin-top: -12px;
        }
      }
      &:not(:last-child) {
        padding-bottom: 20px;
      }
      
      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -27px;
        height: 100%;
        width: 5px;
        z-index: 9;
        top: 22px;
        background-color: #ccc;
      }
      
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 22px;
        left: -35px;
        width:  $small-circle;
        height: $small-circle;
        border: 2px solid #CCC;
        border-radius: 50%;
        background-color: #CCC;
        width: $small-circle;
        height: $small-circle;
        z-index: 10;
      }
      
      &.is-done {
        &::before {
          background-color: $htPrimaryLight;
          z-index: 8;
          top: 25px;          
        }
        &::after {
          // content: "✔";
          font-size: 10px;
          color: $main-white-color;
          text-align: center;
          // border: 2px solid $htPrimaryLight;
          // background-color: $htPrimaryLight;
          width:  22px;
          height: 22px;
          z-index: 10;
          left: -35px;
          border:0 !important;
          background: url("../svg/check-circle-solid.svg") no-repeat top center scroll #fff;
          background-size:22px ;
        }
      }
      
      &.current {
        &::before {
          background-color: #ccc;
          z-index: 1;
          margin-top: 37px;
          height:85%;
          top: 19px;
        }        
        &::after {
          // content: "...";
          font-size: 10px;
          color: $main-white-color;
          text-align: center;
          width: $small-circle;
          height: $small-circle;
          background-size:$small-circle ;
          background-repeat: no-repeat !important;
          z-index: 10;
          background-position: 44% 50%  !important;
          background: url("../images/company_icon.png") no-repeat top center scroll $htPrimaryLight;
          background-size:contain;
          border: 2px solid #fff;
          border-radius: 30%;
          svg{
            color:white
          }
        }
        .indicatorContainer{
          &::after {
            border: 5px solid $htPrimaryLight;
          }
        }
      }
      &.last{
        // border:2px solid red;
        &::before {
         display: none;
        }
        // &::after {
        //   display: none !important;
        // }
        .indicatorContainer::before{
          // &::before {
            display: none;
          // }
        }
      }
      &.beforeActive{
        &::before {
          height:89% !important;
         }
      }
    }
    .indicatorContainer{
      &::before {
        display: inline-block;
        content: '';
        position: absolute;
        left: -33px;
        height: 100%;
        width: 17px;
        top: 20px;
        background: $main-white-color;
      }
      
      &::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 10px;
        left: -47px;
        width: $small-circle;
        height: $small-circle;
        border: 5px solid $main-white-color;
        border-radius: 50%;
        background-color: $main-white-color;
        width:36px;
        height:36px;
        
      }
    }

    
    strong {
      display: block;
    }
  }
  svg{
    color:white
  }
}
.civilIdForm{
  #file-upload-button{
    border:2px solid green !important
  }
  input{
    display: flex !important;
  }
}
.typeIcon{
  width:25px !important;
  position: absolute;
  top:30px;
  right:30px;
  color:$htPrimaryLight;
  font-size: 18px;
  z-index: 999 !important;
  &.productDetailIcon{
    right:30px !important;
    top:30px  !important;
  }
  &.detailPage{
    width:37px !important;
  }
}

.dark-grey{
  color: #979ea9 !important;
  border: solid !important;
  box-shadow: 0 2px 2px 0 rgba(60, 72, 88, 0.14), 0 3px 1px -2px rgba(60, 72, 88, 0.2), 0 1px 5px 0 rgba(60, 72, 88, 0.12)!important;
  border-width: 0.05rem !important;
  border-color: #dbdcde !important;
  background-color: #f4f7fa!important;
}
.disabled{
  i, .color-pl{
    color: $darkGrey !important;
  }
} 

.remove-voucher{
  display: flex !important;
  color:#00acc1 !important;
  cursor: pointer;
  font-size: 0.75rem !important;
  margin-top: 10px;
  font-weight: 500!important;
  text-transform: none;
  @include respond-below(sm) {
    font-size: 0.65rem !important;
  }
}

.text-badge {
  right: 0;
  left: 0;
  top: 48%;
  padding: 0px !important;
  margin-bottom: 1.5rem !important;

}
.verification-text-Background{
  background-color: #ffc107 !important;
  padding: 5px 10px !important;
  color: #006978!important;

  &.bg-pl {
    background-color: $htPrimaryLight !important;
    color: #fff !important;
  }
}

.quick-add-text-badge {
  right: 0;
  left: 0;
  top: 48%;
  padding: 0px !important;
  margin-bottom: 0.8rem !important;
}

.overflowVisible{
  overflow: visible !important;
}

.rdt.rdtOpen .rdtPicker {
  @include respond-below(sm){
     margin-bottom: 100px;
  }
}
.company-title{
  @include respond-below(sm) {
    text-align: center !important;
  }
}
.ltr-view{
  direction: ltr !important;
}

.MuiInputAdornment-positionEnd-808 {
  margin-left: 15px !important
}

.listing-images{
  padding: 5px;
}

.status-badge{
  margin-top: 0.7rem !important;
}

#main-header{
  display: -webkit-box !important;
}
.listed-product-description{
    width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include respond-below(sm) {
      width: 150px;
    }
}

.request-item{
  font-size: 1rem !important; 
  @include respond-below(sm) {
    margin-top: 15px !important;
  }
}

.mobile-detail-cart{
  @include respond-below(sm) {
    position: absolute;
    left: 4%;
    top: 3%;
    right:auto;
  }
}

.mobile-detail-cart-ar{
  @include respond-below(sm) {
    position: absolute;
    left:auto;
    top: 3%;
    right:4%;
    transform: rotate(180deg) !important;
    -webkit-transform: rotate(180deg) !important;
    -moz-transform: rotate(180deg) !important;
    -ms-transform:rotate(180deg) !important;
  }
}

.product-detail-page .fa-arrow-left{
  color:$htPrimaryLight!important
}

.hide-web{
  @include respond-above(sm) {
    display: none !important
  }
}
.product-detail-sell-now-ar{
 margin-right: 3px !important;
 margin-top: 0px !important;
 margin-bottom: 0px !important;
 margin-left: 3px !important;
}
.category_tree{
  .category_dropdown{
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }
  .category_wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img{
      width: auto !important;
      height: 43px !important;
      @include respond-below(md) {
        height: 20px !important;
      }
    }
    .title{
      margin-top:1rem;
      // margin-bottom:0.5rem;
      text-transform: none;
      color: $htPrimaryGreen;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 120px;
      font-size: 12px;
      line-height: 1.42857143;
      @include respond-below(md) {
        display:none !important;;
      }
    }
    &.category_item{
      flex-direction: row;
      justify-content: flex-start;
      img{
        width: 25px !important;
        height: 25px !important;
        object-fit: contain;
      }
      .title{
        padding-left:1rem;
        padding-right:1rem;
        margin:0;
        max-width: 100% !important;;
        text-align: left;
        @include respond-below(md) {
          display:flex !important;;
        }
      }
    }
  }
}
.category_button{
  width:90px;
  @include respond-below(md) {
    width:unset !important;;
    padding-left:0.5rem;;
    padding-right:0.5rem ;;
  }
  button{
    margin:0 !important;
    padding:0 !important;
    min-width:unset !important;
    width:50px!important;
    @include respond-below(md) {
      width:unset !important;
    }
    &:hover{
      background-color: unset  !important;
    }
  }
  &.childButton{
    button{
      width:100% !important;      
    }
  }
}
.w100 {
  width: 100% !important;
}
.categeory_dropdown_col{
  @include respond-below(xs) {
    position: unset !important;
  }
}

.product-title-width{
  max-width: 380px !important;
  @include respond-below(sm) {
    max-width: 200px !important;
  }
}

.offer-button{
  @include respond-below(sm) {
   font-size: 0.765rem !important;
  }
}

.profile-name{
  font-size: 1.125rem !important;
}
.accepted{
  background-color: #43a047!important;
}
.rejected{
  background-color: #d32f2f !important;
}
.waiting{
  background-color:#FFC107 !important;
}
.quantity_form{
  button{
    min-width: unset !important;
    width:75px !important;
    height:55px !important;
  }
}
.sell_qty{
  margin-top:2rem !important;
  margin-bottom:3rem !important;
}
.mobile_facet_apply{
  position: fixed !important;
  bottom: 3.5rem;
  left: 10%;
  width: 80% !important;
}
.request-item-price{
  display: flex !important;
  align-items:baseline !important;
}
.edit_instant{
  display: inline-block !important;
}

.request-price-view-en{
  margin-right: 5px !important;
  margin-left: 0px !important;
}
.request-price-view-ar{
  margin-right: 0px !important;
  margin-left: 5px !important;
}
